navigator.sendMail = function (message /* appendix */) {
  if (!navigator.onLine) return;
  let xhr = new XMLHttpRequest();
  //xhr.withCredentials = false;
  let data = new FormData();
  data.append("task", "simplemail");
  data.append("message", message);
  // if (appendix) data.append("appendix", appendix);
  xhr.open("POST", "https://aitazan.osrandil.de/gameservices.php", true);
  xhr.setRequestHeader("Accept", "text/plain");
  xhr.onload = function () {
    console.dir("success", xhr.responseText);
  };
  xhr.onerror = function (e) {
    console.dir("error", e);
  };
  xhr.send(data);
};
